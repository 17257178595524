.link-action {
  text-decoration: none !important;
  text-transform: none !important;

  &:not([disabled]) {
    color: #4aa6ce !important;
  }
}

.column-publish {
  display: flex;
  flex-direction: column;
}

.btn-outlined-error {
  color: red !important;
}
